
import { Component, Vue } from 'vue-property-decorator'
import { apiPaySetKdConfig, apiPayGetKdConfig } from '@/api/setting'

@Component({
    components: {
    }
})
export default class ServiceSetting extends Vue {
    form: any = {
        merchant_id: '', // 商户号
        secret_key: '', // 密钥
    }
	
	// 表单校验
	rules: object = {
	    merchant_id: [
	        {
	            required: true,
	            message: '请输入商户号',
	            trigger: 'blur'
	        }
	    ],
		secret_key: [
		    {
		        required: true,
		        message: '请输入密钥',
		        trigger: 'blur'
		    }
		],
	}

    /** S Methods **/

    getPayConfig() {
        apiPayGetKdConfig().then(res => {
            this.form = res
        })
    }

    onSubmit(formName: string) {
		
		const refs = this.$refs[formName] as HTMLFormElement
		refs.validate((valid: boolean): void => {
		    if (!valid) {
		        return
		    }
		
		    // 请求发送
		    apiPaySetKdConfig({ ...this.form }).then(res => {
		        this.getPayConfig()
		    })
		})
    }

    /** E Methods **/

    /** Life Cycle **/
    created() {
        // 初始化数据
        this.getPayConfig()
    }
}
